.inner {
    background-color: rgba(18, 40, 11, 0.6);
    align-self: center;
    width: 85%;
    height: 85%;
    display: flex;
    flex-direction: row;
    z-index: 2;
    border-radius: 24px;
}

.label-campo {
    font-family: telegraf-400;
    font-size: 15px;
    display: flex;

    margin-bottom: 5px;
    color: white;
}

.input-style {
    background: transparent;
    margin-bottom: 3%;
    color: white;
}

#title-share-idea {
    color: var(--neutral-neutral-0, #fff);
    font-feature-settings: "clig" off, "liga" off;
    font-family: inter-bold;
    font-size: 3vw;
    font-style: normal;
    font-weight: 700;
    line-height: 120%; /* 76.8px */
    text-align: left;
}

.select-style {
    background: transparent;
    margin-bottom: 1vw;
    width: 100%;
    color: white;
}
.ant-select-selector:hover,
.ant-select-selector:focus-within {
    border: 1px solid #97d700 !important;
}

.ant-input:hover,
.ant-input:focus-within {
    border: 1px solid #97d700 !important;
}

.ant-btn-default:hover,
.ant-btn-default:focus-within {
    border: none;
}

:where(.css-dev-only-do-not-override-ffcwkg).ant-select-single
    .ant-select-selector {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: white;
    font-size: 14px;
    line-height: 1.5714285714285714;
    list-style: none;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
        "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
        "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    display: flex;
    border-radius: 6px;
}

#turn-back-button {
    background-color: transparent;
    color: white;
    width: 150px;
    outline: 0 transparent !important;
    box-shadow: 0 0 0 #000;

    &:hover {
        outline: 0px transparent !important;
        background-color: rgba(255, 255, 255, 0.6);
        border: 0 transparent !important;
        border-style: none;
    }
}

#turn-back-button span {
    width: 100%;
    color: var(--neutral-neutral-0, #fff);
    font-feature-settings: "clig" off, "liga" off;
    font-family: quicksand-500;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 132%; /* 23.76px */
}

#create-idea-button {
    width: 150px;
    border-color: #eeeeee;
}

#create-idea-button span {
    color: var(--neutral-neutral-800, #2f2f2f);
    font-feature-settings: "clig" off, "liga" off;

    /* Title/title-s */
    font-family: quicksand-600;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 132%; /* 23.76px */
}
