
#main-container {
    height: 100%;
    display: flex;
    flex-direction: row;
}
#left-container {
    height: 100%;
    position: relative;
    background: url('../assets/tus_ideas_background_left.jpg') no-repeat;
    scale: 100%;
    background-size: cover;
}
#right-container {
    height: 100%;
    position: relative;
    background: url('../assets/tus_ideas_background_right.jpg') no-repeat;
    background-size: cover;
}
#title-muguerza {
    display: flex;
    flex-grow: 1;
    justify-content: center;
    align-items: end;
    margin-block: 0 55px;
    color: var(--neutral-neutral-0, #fff);
    text-align: center;
    font-feature-settings: "clig" off, "liga" off;
    font-family: quicksand-500;
    font-size: 1.5vw;
    font-style: normal;
    font-weight: 500;
    line-height: 125%;
}