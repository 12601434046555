.swiper {
    width: 100%;
    height: 100vh;
}

.swiper-slide {
    text-align: center;
    font-size: 18px;
    color: white;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
}

#idea-container {
    margin-top: 25px;
    display: flex;
    flex: 8;
    flex-direction: column;
    text-align: left;
}

#idea-title {
    color: var(--primary-1-primary-1900, #671e75);
    font-feature-settings: "clig" off, "liga" off;
    font-family: inter-bold;
    font-size: 3.5vw;
    font-style: normal;
    font-weight: 700;
    line-height: 120%; /* 67.2px */
    margin: 0;
}

#idea-information {
    color: var(--neutral-neutral-800, #2f2f2f);
    font-feature-settings: "clig" off, "liga" off;
    font-family: quicksand-600;
    font-size: 1.5vw;
    font-style: normal;
    font-weight: 600;
    line-height: 132%;
}

#idea-subtitle {
    color: var(--primary-1-primary-1900, #671e75);
    font-feature-settings: "clig" off, "liga" off;
    font-family: quicksand-700;
    font-size: 1.3vw;
    font-style: normal;
    font-weight: 700;
    line-height: 132%;
    margin: 25px 0 5px 0;
}

.idea-author-name {
    color: var(--neutral-neutral-800, #2f2f2f);
    font-feature-settings: "clig" off, "liga" off;
    font-family: quicksand-600;
    font-size: 1.3vw;
    font-style: normal;
    font-weight: 600;
    line-height: 132%; /* 31.68px */
}

.idea-author-job {
    color: var(--neutral-neutral-800, #2f2f2f);
    font-feature-settings: "clig" off, "liga" off;
    font-family: quicksand-400;
    font-size: 1.3vw;
    font-style: normal;
    font-weight: 400;
    line-height: 132%;
}

#idea-unit-information {
    color: var(--neutral-neutral-800, #2f2f2f);
    font-feature-settings: "clig" off, "liga" off;
    font-family: quicksand-600;
    font-size: 1.3vw;
    font-style: normal;
    font-weight: 600;
    line-height: 132%;
}

#idea-footer {
    flex: 2;
    display: flex;
    align-items: self-end;
    justify-content: space-between;
}

#like-container {
    display: flex;
}

#like-container #text {
    display: flex;
    flex-direction: column;
    text-align: end;
    justify-content: center;
}

#like-container #text h5 {
    color: var(--neutral-neutral-800, #2f2f2f);
    text-align: right;
    font-feature-settings: "clig" off, "liga" off;
    font-family: quicksand-600;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 132%;
    margin: 0;
}

#like-container #text span {
    color: var(--neutral-neutral-600, #63666a);
    font-feature-settings: "clig" off, "liga" off;
    font-family: quicksand-600;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 132%;
}

#like-button {
    display: flex;
    width: 60px;
    height: 60px;
    margin-left: 15px;
    justify-content: center;
    align-items: center;
    border-radius: 65.455px;
    background: var(--primary-1-primary-1900, #671e75);
}

#like-button:hover {
    background: var(--primary-1-primary-1900, #72277f);
    cursor: pointer;
}

#like-button img {
    width: 35px;
    height: 35px;
}
