#main-container {
    height: 100vh;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    color: black;
}

#header {
    width: 100%;
    flex: 1.5;
    display: flex;
    flex-direction: 'row';
}

#news-title {
    flex: 3;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 10%;
}

#title {
    font-size: 2.5em;
    font-weight: bold;
    font-family: telegraf-400;
}

#subtitle {
    font-size: 1.5em;
    font-family: telegraf-400;
}

#news-icon {
    flex: 3;
}

#icon_ideas_1 {
    background: url('../assets/icon_ideas_1.png') right no-repeat;
    background-size: contain;
}

#content {
    width: 100%;
    flex: 4.5;
    display: flex;
    flex-direction: 'row';
}

#news-image {
    flex: 3;
    margin-left: 10%;
}

#news-text {
    flex: 3;
    padding: 1% 2.5%;
    font-size: 1.25em;
    font-family: telegraf-400;
}