@font-face {
	font-family: 'telegraf-400';
	src: url('./assets/fonts/telegraf_regular_400-webfont.woff') format('woff');
}

@font-face {
	font-family: 'telegraf-800';
	src: url('./assets/fonts/telegraf_ultrabold_800-webfont.woff') format('woff');
}

@font-face {
	font-family: 'inter-medium';
	src: url('./assets/fonts/Inter-Medium.ttf');
}

@font-face {
	font-family: 'inter-bold';
	src: url('./assets/fonts/Inter-Bold.ttf');
}
@font-face {
	font-family: 'figtree-bold';
	src: url('./assets/fonts/Figtree-Bold.ttf');
}

@font-face {
	font-family: 'quicksand-400';
	src: url('./assets/fonts/Quicksand-Regular.ttf');
}

@font-face {
	font-family: 'quicksand-500';
	src: url('./assets/fonts/Quicksand-Medium.ttf');
}

@font-face {
	font-family: 'quicksand-600';
	src: url('./assets/fonts/Quicksand-SemiBold.ttf');
}

@font-face {
	font-family: 'quicksand-700';
	src: url('./assets/fonts/Quicksand-Bold.ttf');
}

body {
	margin: 0;
	overflow: hidden;
}
