#left-container-header {
    display: flex;
    flex-direction: row;
    margin-top: 35px;
    margin-inline: 45px;
    justify-content: space-between;
}

#left-container-header img {
    height: 14vh;
}

#left-container-text {
    display: flex;
    flex-grow: 6;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-inline: 10vw;
}

#left-container-text h1 {
    color: var(--neutral-neutral-0, #fff);
    text-align: center;
    font-feature-settings: "clig" off, "liga" off;
    font-size: 2.8vw;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    margin: 0 0 10px 0;
    font-family: inter-bold;
}

#left-container-text h3 {
    color: var(--neutral-neutral-0, #fff);
    text-align: center;
    font-feature-settings: "clig" off, "liga" off;
    font-size: 1.2vw;
    font-style: normal;
    font-weight: 500;
    line-height: 125%;
    margin: 0;
    font-family: quicksand-500;
}

#title-muguerza {
    display: flex;
    flex-grow: 1;
    justify-content: center;
    align-items: end;
    margin-block: 0 55px;
    color: var(--neutral-neutral-0, #fff);
    text-align: center;
    font-feature-settings: "clig" off, "liga" off;
    font-family: quicksand-500;
    font-size: 1.5vw;
    font-style: normal;
    font-weight: 500;
    line-height: 125%;
}

#left-container-text Button {
    margin-top: 25px;
    padding-inline: 1.5vw;
    height: 6vh;
    color: #2f2f2f;
    font-family: quicksand-600;
    font-size: 1.2vw;
    font-weight: 600;
}

.ideas-container {
    display: flex;
    flex-direction: column;
    width: 85%;
    justify-content: center;
    align-items: center;
    height: 50%;
}

#finish-ideas {
    background: var(--secondary-2-secondary-2600, #468f2a);
    color: var(--neutral-neutral-0, #fff);
}

#new-ideas {
    background: var(--secondary-2-secondary-2300, #addc91);
}

.ideas-header {
    display: flex;
    padding: 1vh 24px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    width: 94%;
    border-radius: 8px;
    font-family: quicksand-600;
    font-size: 1.2vw;
    font-style: normal;
    font-weight: 600;
    line-height: 132%;
}

.ideas-wrapper {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2vh 10px;
    margin-top: 1rem;
    width: 100%;
    max-height: 70%;
    overflow-y: auto;
}

.item-list {
    display: flex;
    justify-content: space-between;
    height: 8vh;
    padding-left: 1vw;
    border-radius: var(--spacing-2, 8px);
    border: 1px solid var(--neutral-neutral-300, #dadada);
    background: var(--neutral-neutral-0, #fff);
}

.item-title {
    color: var(--neutral-neutral-800, #2f2f2f);
    font-feature-settings: "clig" off, "liga" off;
    padding-top: 1vh;
    font-family: quicksand-600;
    font-size: 1vw;
    font-style: normal;
    font-weight: 600;
    line-height: 132%;
}

.item-date {
    color: var(--neutral-neutral-600, #63666a);
    font-feature-settings: "clig" off, "liga" off;
    font-family: quicksand-400;
    font-size: 0.8vw;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
}

.item-tag {
    border-top-right-radius: var(--spacing-2, 8px);
    border-bottom-right-radius: var(--spacing-2, 8px);
    width: 0.6vw;
    height: 100%;
}

.tag-new-idea {
    background: var(--secondary-2-secondary-2300, #addc91);
}

.tag-finish-idea {
    background: var(--secondary-2-secondary-2600, #468f2a);
}
